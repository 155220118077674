import React from 'react';
import { FiClock } from 'react-icons/fi';
import {
  ItemSubtitle,
  ItemText,
  NotificationDate,
  TextContainer,
} from './styles';
import { DateColumnFormatter } from '../../../../helpers/tableHelpers';
import { IParsedContentNotification } from '../../DropdownNotification';

export interface IDefaultNotificationProps extends IParsedContentNotification {
  isOpen?: boolean;
  created_at: string;
}

const OpenContainer: React.FC<IDefaultNotificationProps> = ({
  text_pt,
  children,
  created_at,
}) => {
  return (
    <>
      <ItemText>{text_pt}</ItemText>
      {children}
      <NotificationDate>
        <FiClock />
        <DateColumnFormatter value={created_at} withHour />
      </NotificationDate>
    </>
  );
};

const CloseContainer: React.FC<IParsedContentNotification> = ({ text_pt }) => {
  if (text_pt) {
    return <ItemSubtitle>{text_pt.slice(0, 30)}...</ItemSubtitle>;
  }
  return <></>;
};

export const DefaultNotification: React.FC<IDefaultNotificationProps> = ({
  isOpen,
  ...rest
}) => {
  return (
    <TextContainer>
      {isOpen ? <OpenContainer {...rest} /> : <CloseContainer {...rest} />}
    </TextContainer>
  );
};
